<template>
  <section v-if="$can('view', 'AlarmeAcesso') && isReady" class="alarm-panel">
    <div class="box" :class="isEditing ? 'box-warning' : 'box-primary'">
      <template v-if="mode == 'editor'">
        <div class="box-header">
          <DashboardPanelTitle
            :panel="panel"
            :connector="equipment"
            :isEditing="isEditing"
            @click.stop.prevent="$emit('panelProperties')"
          />
          <slot name="toolbar"></slot>
        </div>
      </template>
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs pull-right ui-sortable-handle"
          v-if="mode != 'editor'"
        >
          <template v-if="historyEnabled">
            <li
              v-bind:class="{active: tab == 0, clicable: tab != 0}"
              :title="$t('titles.view_history')"
              v-if="$can('view', 'HistoricoAlarmeAcesso')"
            >
              <a aria-expanded="true" v-on:click.prevent.stop="tab = 0">
                <i class="fa fa-history"></i>
              </a>
            </li>
            <li
              v-bind:class="{active: tab == 1, clicable: tab != 1}"
              :title="$t('titles.view_config')"
              v-if="$can('view', 'AlarmeAcesso')"
            >
              <a aria-expanded="true" v-on:click.prevent.stop="tab = 1">
                <i class="fa fa-cog"></i>
              </a>
            </li>
          </template>
          <li class="pull-left header">
            <div class="tab-toolbar-control-group" v-if="tab == 1">
              <DashboardPanelTitle :panel="panel" :connector="equipment" />
            </div>
            <template v-if="historyEnabled">
              <div class="tab-toolbar-control-group" v-if="tab == 0">
                <DashboardPanelTitle :panel="panel" :connector="equipment" />
                <div class="tab-toolbar-control" style="margin: 0 5px">
                  <DatetimeRangePicker
                    v-if="
                      $can('view', 'HistoricoAlarmeAcesso') &&
                      isReady &&
                      showDatetimePicker
                    "
                    v-on:interval-changed="intervalChanged"
                    :startRef="startRef"
                    :endRef="endRef"
                    :min="queryLimit * 24"
                    :isHistory="true"
                    @dateRangePickerEvent="onDateRangePickerEvent"
                  />
                </div>
                <!-- long/short interval filter -->
                <div
                  class="tab-toolbar-control"
                  v-if="
                    true //toolbar.largelongIntervalSelection
                  "
                >
                  <IntervalDropDown v-model="queryLimit" />
                </div>
                <div class="tab-toolbar-control" v-if="!downloadOnly">
                  <dropdown
                    v-if="dropdownOptions.length"
                    position="right"
                    v-bind:data="dropdownOptions"
                    v-on:apply="dropdownApply"
                    v-bind:multiple="true"
                    :title="`${$t('titles.filter_by')}: ${$tc('data', 1)}`"
                  >
                    <span>
                      <i class="fa fa-filter toolbar-btn-icon"></i>
                    </span>
                  </dropdown>
                </div>
                <!-- download button -->
                <div
                  class="tab-toolbar-control"
                  v-if="
                    $can('view', 'HistoricoAlarmeAcesso') &&
                    isReady &&
                    !downloadOnly
                  "
                >
                  <DownloadButton
                    tableId="alarmHistoryReport"
                    v-on:ready="downloading = true"
                    v-on:done="downloading = false"
                    v-bind:downloading="downloading"
                  ></DownloadButton>
                </div>
              </div>
            </template>
            <slot name="toolbar"></slot>
          </li>
        </ul>
        <div class="tab-content panel-content" ref="tabContent">
          <div class="active tab-pane">
            <EquipmentAlarmSearchBar
              :alarmList="alarmList"
              :panel="panel"
              @filtered="onFilteredAlarmListId"
            />
            <EquipmentAlarmConfigurationDisplay
              v-if="tab"
              :key="cid"
              :mode="mode"
              :equipment="equipment"
              :alarmList="alarmList"
              :filteredAlarmIdList="selected"
              :display="display"
              :panelOptions="panelOptions"
              :busy="busy"
              @refresh="fetchAlarmList"
              @widthError="onWidthError"
            />
            <template v-else-if="historyEnabled">
              <HistoryDownloadContainer
                v-if="downloadOnly && showDatetimePicker"
                :startDate="startDate"
                :endDate="endDate"
                :items="historyEnabledFilteredAlarmList"
                :selected="selected"
                idFieldName="alarm_ids"
              />
              <EquipmentAlarmHistoryDisplay
                v-else
                :mode="mode"
                :equipment="equipment"
                :display="display"
                :panelOptions="panelOptions"
                :startDate="startDate"
                :endDate="endDate"
                :downloading="downloading"
                :downloadOnly="downloadOnly"
                :busy="busy"
                :alarmList="historyEnabledFilteredAlarmList"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {uniqBy} from "lodash";
import {isMQTT} from "@/services/connector.js";

import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import DownloadButton from "@/components/download-button.vue";
import EquipmentAlarmConfigurationDisplay from "@/components/equipment-alarm-configuration-display.vue";
import EquipmentAlarmHistoryDisplay from "@/components/equipment-alarm-history-display.vue";
import AlarmForm from "@/components/control-sidebar/property-editors/alarm-form.vue";
import Dropdown from "@/plugins/dropdown/dropdown.vue";
import IntervalDropDown from "@/components/widgets/interval-dropdown.vue";
import HistoryDownloadContainer from "@/components/history/history-download-container.vue";
import EquipmentAlarmSearchBar from "@/components/equipment-alarm-search-bar.vue";
import DashboardPanelTitle from "@/components/dashboard-panel-title.vue";

const nowRounded = function () {
  let value = moment();
  // value.minute(Math.round(value.minute() / 30) * 30);
  value.second(0);
  return value;
};
export default {
  name: "EquipmentAlarmPanel",
  components: {
    DatetimeRangePicker,
    DownloadButton,
    EquipmentAlarmConfigurationDisplay,
    EquipmentAlarmHistoryDisplay,
    IntervalDropDown,
    HistoryDownloadContainer,
    EquipmentAlarmSearchBar,
    Dropdown,
    DashboardPanelTitle
  },
  data: () => ({
    tab: 1,
    startRef: null,
    endRef: null,
    startDate: null, // todo: move it to the selection component
    endDate: null,
    downloading: false,
    busy: false,
    firstTime: true,
    isDatetimePickerVisible: false,
    showDatetimePicker: true,
    queryLimit: 0,
    selected: [],
    cid: 0,
    endsNow: false
  }),
  provide() {
    return {
      panel: this.panel
    };
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    display: {
      type: Object,
      default: null
    },
    panelName: {
      type: String,
      default: "alarms",
      required: false
    },
    tab_id: {
      type: Number,
      default: -1,
      required: false
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    title: {
      type: String,
      default: "alarms",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    panel: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  computed: {
    isMQTT() {
      return isMQTT(this.equipment);
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    isReady() {
      return (
        // this.equipment != null && this.display != null && this.panelName != null
        this.display != null && this.panelName != null
      );
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    alarmListFromStore() {
      return this.$store.getters["dashboard/alarmList"] || [];
    },
    deviceId() {
      return this.$store.getters.deviceId;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    alarmList() {
      let lst = [];
      let data;
      if (this.panelDataIdList.length) {
        lst = this.alarmListFromStore;
        let connectorIds = this.panelDataIdList
          .filter((id) => /connector_\d+_id/.test(id))
          .map((id) => parseInt(id.match(/\d+/)[0]));
        if (connectorIds.length == 0) {
          lst = lst.filter(
            ({data_id}) => this.panelDataIdList.indexOf(data_id) >= 0
          );
        } else {
          lst = lst.filter(
            ({connector_id}) => connectorIds.indexOf(connector_id) >= 0
          );
        }
      } else if (this.allAlarms) {
        lst = this.alarmListFromStore;
      } else if (this?.equipment?.id) {
        lst = this.alarmListFromStore.filter(
          ({connector_id}) => connector_id == this.equipment.id
        );
      }
      if (this.deviceId) {
        lst = lst.filter(({device_id}) => device_id == this.deviceId);
      }
      return lst.map((item) => {
        if (item?.data?.id) {
          data = this.dataList.find(
            ({id}) => parseInt(id) == parseInt(item.data.id)
          );
          if (data) {
            return {...item, ...{data: data}};
          }
        }
        return item;
      });
    },
    historyEnabledAlarmList() {
      return (this?.alarmList || []).filter(
        ({history_enabled}) => history_enabled
      );
      // return this.alarmList;
    },
    historyEnabledFilteredAlarmList() {
      return this.historyEnabledAlarmList.filter(
        ({id}) => this.selected.indexOf(id) >= 0
      );
    },
    panelDataIdList() {
      // mockup
      // return [{ data_id: 28239 }, { data_id: 28240 }].map(
      //   ({ data_id }) => data_id
      // );
      return this.allAlarms
        ? []
        : (this?.panel?.options?.dataList || [])
            .filter(({checked}) => checked)
            .map(({data_id}) => data_id);
    },
    allAlarms() {
      return this?.panel?.options?.allAlarms || false;
    },
    historyEnabled() {
      return (
        !this.allAlarms &&
        this?.panel?.options?.historyPanel &&
        this.alarmList.some(({history_enabled}) => history_enabled)
      );
    },
    canShowAlarmList() {
      return this.allAlarms && this.mode == "editor"
        ? false
        : this.tab
        ? true
        : false;
    },
    dropdownOptions() {
      return (this.historyEnabledAlarmList || [])
        .map((alarm) => {
          return {
            data: alarm,
            label: alarm.name.toUpperCase(),
            value: alarm.id,
            title: `#${alarm.id} ${alarm.name}`,
            selected: this.selected.indexOf(alarm.id) >= 0
          };
        })
        .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    },
    downloadOnly() {
      return this.queryLimit == this?.contract?.history_long_limit_to_query;
    }
  },
  watch: {
    busy(n, o) {
      if (!n && o && !this._refreshTimer) {
        if (this.isMQTT) return;
      }
    },
    equipment: {
      handler(n, o) {
        if ((!o || !o.id) && n && n.id) {
          this.fetchAlarmList();
        }
      },
      deep: true,
      immediate: true
    },
    panelDataIdList: {
      handler(n) {
        if (n && n.length) {
          this.fetchAlarmList();
        }
      },
      deep: true,
      immediate: true
    },
    allAlarms: {
      handler(n) {
        if (n) {
          this.fetchAlarmList();
        }
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "AlarmForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: AlarmForm
            });
          }
        }
      },
      immediate: true
    },
    queryLimit(n) {
      if (n) {
        if (this.downloadOnly) {
          // this.selected = this.historyEnabledAlarmList.map(({ id }) => id);
        }
        this.showDatetimePicker = false;
        this.initIntervalRef();
        this.$nextTick(() => {
          this.showDatetimePicker = true; // force the calendar to be mounted again
        });
      }
    },
    tab(n, o) {
      if (!n && o) {
        let curHeight = this.$refs.tabContent.getBoundingClientRect().height;
        // let minHeight = parseInt(
        //   (this?.panel?.style["min-height"] || "0px").replace(/px/, "")
        // );
        // if (curHeight>minHeight){
        this.$refs.tabContent.style["min-height"] = `${curHeight}px`;
        // }
        this.startRef = this.startDate;
        this.endRef = this.endDate;
        this.showDatetimePicker = false;
        this.$nextTick(() => {
          this.showDatetimePicker = true; // force the calendar to be mounted again
        });
      }
    },
    deviceId(n, o) {
      if (n != o) {
        if (
          this.fetchedAll ||
          (n &&
            (this.equipmentDataList || []).some(
              ({device}) => device && device.id == n
            ))
        ) {
          return;
        } else if (!this.fetchedAll) {
          this.fetchAlarmList();
        }
      }
    },
    alarmList(n) {
      this.cid += 1;
    }
  },
  methods: {
    onFilteredAlarmListId(lst) {
      if (
        !this.selected.length &&
        lst.length &&
        this.$route.query.alarm &&
        lst.find((id) => parseInt(id) == this.$route.query.alarm)
      ) {
        this.$set(this, "selected", [parseInt(this.$route.query.alarm)]);
        return;
      }
      this.$set(this, "selected", lst);
    },
    dropdownApply(items) {
      var selected = items.map(function (item) {
        return item.value;
      });
      this.$set(this, "selected", selected);
    },
    intervalChanged(data) {
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.updateEndsNow();
    },
    fetchAlarmList() {
      if (this.busy || !this.canShowAlarmList) {
        return;
      }
      var query = null;
      if (this?.panelDataIdList?.length) {
        query = {
          resource: "alarm",
          forceUpdate: true
        };
        let connectorIds = this.panelDataIdList
          .filter((id) => /connector_\d+_id/.test(id))
          .map((id) => id.match(/\d+/)[0]);
        if (connectorIds.length == 0) {
          query.data_ids = this.panelDataIdList;
        } else if (connectorIds.length == 1) {
          query.connectorId = connectorIds[0];
        } else {
          // TODO: validate if alarm API supports multiple connectors
          query.connector_ids = connectorIds;
        }
      } else if (this.allAlarms) {
        query = {
          resource: "alarm",
          base_model: false,
          forceUpdate: true
        };
      } else if (this?.equipment?.id) {
        query = {
          resource: "alarm",
          connectorId: this.equipment.id,
          forceUpdate: true
        };
      }
      if (!query) return;
      if (this.deviceId) {
        query.device_id = this.deviceId;
      } else {
        this.fetchedAll = true;
      }
      this.busy = true;
      this.$store.dispatch("dashboard/fetchResourcesFrom", query).then(() => {
        this.busy = false;
        if (this.firstTime) {
          // if (!this.allAlarms)
          // this.fetchDataList();
          this.firstTime = false;
          if (this.mode != "editor") {
            this.$root.$emit("panel:resized");
          }
          if (this.allAlarms) {
            this.$nextTick(() => {
              this.selected = (this.alarmList || []).map(({id}) => id);
            });
          }
          if (this.mode == "editor") {
            this.$root.$emit("dashboard:editor", {
              action: "updateWidth"
            });
          }
        }
      });
    },
    fetchDataList() {
      if (!this?.equipment?.id && !this.allAlarms) return;
      // whether not yet in memory, fetchs the new ones
      let prvIdList = [];
      (this.$store.getters["dashboard/dataList"] || []).map(({id}) => id);
      let newIdList = uniqBy(this.alarmList.map(({data_id}) => data_id));
      let ids = newIdList.filter((n) => !prvIdList.some((p) => p == n));
      if (!ids.length) return;
      this.$store
        .dispatch("dashboard/fetchResources", {
          list: ids,
          resource: "data"
        })
        .then(() => {});
    },
    setSideBar() {
      if (this.sidebar.name != "AlarmForm") {
        this.$root.$emit("controlSidebar:setContent", AlarmForm);
      }
    },
    onWidthError() {
      // this.$el.style.height = "1px";
    },
    onDateRangePickerEvent(e) {
      if (e == "show") {
        if (this._prev_scroll == undefined) {
          this._prev_scroll = this.$el.style.overflow;
        }
        this.$el.style.overflow = "visible";
        this.isDatetimePickerVisible = true;
      } else if (e == "hide") {
        if (this._prev_scroll != undefined) {
          this.$el.style.overflow = this._prev_scroll;
        }
        this.isDatetimePickerVisible = false;
      }
    },
    initIntervalRef() {
      // start
      let dftInterval = Math.abs(this?.display?.history?.interval || 24);
      let days = this.downloadOnly ? this.queryLimit : dftInterval / 24;
      let interval = -1 * (Math.abs(days) * 24);
      let value = moment().add(interval, "hour");
      // value.minute(Math.round(value.minute() / 30) * 30);
      value.second(0);
      this.startRef = value;
      // end
      this.endRef = nowRounded();
      // max selectable interval (min date)
      this.minRef = -1 * (Math.abs(this.queryLimit || 30) * 24); // hour based calendar
      this.startDate = this.startRef;
      this.endDate = this.endRef;
      this.updateEndsNow();
    },
    onUserRefresh() {
      if (!this.endsNow || !this.showDatetimePicker) return;
      this.initIntervalRef();
      // required by the jquery calendar to get its visible interface updated
      this.showDatetimePicker = false;
      this.$nextTick(() => {
        this.showDatetimePicker = true;
      });
    },
    updateEndsNow() {
      // important: can not be dynamic/reactive
      this.endsNow =
        moment().format("YYMMDDHHmm") == this.endDate.format("YYMMDDHHmm");
    }
  },
  mounted() {
    // this.startDate = this.startRef;
    this.initIntervalRef();
    this.showDatetimePicker = true;
    if (this.tab_id >= 0) {
      this.tab = this.tab_id;
    }
  },
  created() {
    this._refreshTimer = null;
    this.queryLimit = this.contract.history_short_limit_to_query;
    this.$root.$on("refreshPage", this.onUserRefresh);
  },
  destroyed() {
    this.$root.$off("refreshPage", this.onUserRefresh);
    if (this._refreshTimer) {
      clearInterval(this._refreshTimer);
      this._refreshTimer = null;
    }
  }
};
</script>

<style scoped>
.tab-toolbar-control-group {
  float: left;
  white-space: nowrap;
}
.tab-toolbar-control {
  display: inline-block;
  vertical-align: top;
  margin-left: 0px;
}
.clicable {
  cursor: pointer;
}
.panel-content {
  flex: 1;
  height: inherit;
  min-height: 300px;
  padding: 5px;
}
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  height: 100%;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

.tab-pane {
  height: 100%;
}

.tab-content {
  height: 1px;
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

div::v-deep > .table-container {
  padding-right: 5px;
}

@media (min-width: 340px) and (max-width: 1300px) {
  .hidden-small {
    display: none !important;
  }
  .nav > li > a {
    /* padding: 10px; */
  }
}
</style>
